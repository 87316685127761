import { ResourceType } from '../../@types'

export default function VirtualLab(props: ResourceType) {
  const { virtualLabUrl } = props

  if (virtualLabUrl) {
    const downloadUrl = 'https://vlabs.sw.siemens.com/console/troubleshooting?'
    const subject = `My Training Resources Issue`
    const body = `\n\nDescribe the issue that occurred: `
    const mailUrl = `mailto:SysAdmin_EDA.sisw@siemens.com?subject=${subject}&body=${body}`

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      window.open(
        downloadUrl,
        'Troubleshooting',
        [
          'scrollbars=yes',
          'resizable=yes',
          'status=no',
          'location=no',
          'toolbar=no',
          'menubar=no',
          'width=600',
          'height=600',
          'left=100',
          'top=100',
        ].join(',')
      )
    }

    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md">
        <h2>Virtual Lab</h2>
        <div>
          <button className="btn btn-outline pull-right" onClick={onClick}>
            Get Citrix Workspace
          </button>
          <p>
            Your session will use our Virtual Lab platform. Open the lab in your browser using HTML5 (no download
            required) or you can install Citrix by clicking on the Get Citrix Workspace button. Click the Start button
            to invoke your lab. If you experience issues, please <a href={mailUrl}>contact us</a>.
          </p>
        </div>

        <iframe
          title="Virtual Lab"
          className="vlab-iframe"
          src={virtualLabUrl}
          width="100%"
          height="100%"
          scrolling="no"
          style={{
            height: '150px',
            width: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      </section>
    )
  }
  return null
}

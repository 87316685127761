import { ResourceType } from '../../@types'

export default function Survey(props: ResourceType) {
  const { surveyUrl } = props

  if (surveyUrl) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Survey</h2>
        <p>
          We would like to hear your opinion. Please click on the below "Take Survey" button to fill our 3-Minute
          survey.
        </p>
        <p>
          <a href={surveyUrl} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
            Take Survey
          </a>
        </p>
      </section>
    )
  }
  return null
}

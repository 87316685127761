import Page from './page'

export default function Error() {
  const subject = `My Training Resources Issue`
  const body = `\n\nDescribe the issue that occurred: `
  const mailUrl = `mailto:SysAdmin_EDA.sisw@siemens.com?subject=${subject}&body=${body}`

  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">Oops, an Error Occurred</h1>
        <p className="text-center">
          Sorry, an error occurred. If this error persists, please contact <a href={mailUrl}>our team</a>.
        </p>
      </section>
    </Page>
  )
}

import React from 'react'
import classNames from 'classnames'

type Props = {
  className?: string,
  name: string,
}

export default function Icon(props: Props) {
  const { className, name } = props
  const id = `icon-${name.replace(/^icon-/, '')}`

  return (
    <svg className={classNames('icon', id, className)}>
      <use xlinkHref={'#' + id} />
    </svg>
  )
}

import { ResourceType } from '../../@types'

export default function GetCertified(props: ResourceType) {
  const { badgeLevel1Url, badgeLevel2Url } = props

  if (badgeLevel1Url || badgeLevel2Url) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Get Certified</h2>
        <p>
          Click on the below link to be taken to your badging exam. If an exam is not yet available you will be taken to
          our current list of Level 1 Badging Certification exam options.
        </p>
        <BadgeLevel1Url {...props} />
        {/*<BadgeLevel2Url {...props} />*/}
      </section>
    )
  }
  return null
}

function BadgeLevel1Url(props: ResourceType) {
  const { badgeLevel1Url } = props

  if (badgeLevel1Url) {
    return (
      <p>
        <a href={badgeLevel1Url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
          Level 1 Badge
        </a>
      </p>
    )
  }
  return null
}

function BadgeLevel2Url(props: ResourceType) {
  const { badgeLevel2Url } = props

  if (badgeLevel2Url) {
    return (
      <p>
        <a href={badgeLevel2Url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
          Level 2 Badge
        </a>
      </p>
    )
  }
  return null
}

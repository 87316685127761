import { ResourceType, ReferenceMaterialType } from '../../@types'

export default function ReferenceMaterials(props: ResourceType) {
  const { referenceMaterials } = props

  if (referenceMaterials.length) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Reference Materials</h2>
        <p>Your resources are available for download by clicking the below button(s).</p>
        {referenceMaterials.map(rm => (
          <ReferenceMaterial key={rm.url} {...rm} />
        ))}
        <p>If you experience download issues, please refresh and retry.</p>
        <p>
          Alternatively, you can retry using the{' '}
          <a href="https://www.google.com/chrome" rel="noopener noreferrer" target="_blank">
            Google Chrome browser
          </a>
          .
        </p>
      </section>
    )
  }
  return null
}

function ReferenceMaterial(props: ReferenceMaterialType) {
  const { extension, name, url } = props
  const filename = `${name}.${extension}`

  return (
    <p>
      <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" download={filename} href={url}>
        {name}
      </a>
    </p>
  )
}

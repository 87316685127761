import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import Page from './page'

export default function Logout() {
  const { logout } = useAuth0()
  useEffect(() => {
    logout({ returnTo: window.location.origin })
  }, [])

  return (
    <Page>
      <section className="flex flex-group">
        <div className="center-block m-t-xl">
          <div className="loader" />
        </div>
      </section>
    </Page>
  )
}

import { ResourceType } from '../../@types'

export default function Vlab(props: ResourceType) {
  const { vlabUrl } = props

  if (vlabUrl) {
    return (
      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md grid-item">
        <h2>Virtual Lab Setup</h2>
        <ol>
          <li>
            Go to{' '}
            <a href={vlabUrl} target="_blank" rel="noopener noreferrer">
              {vlabUrl}
            </a>{' '}
            and click &quot;Get Started Now&quot;.
          </li>
          <li>You may need to create your Mentor login.</li>
          <li>Follow the instructions that appear on your screen.</li>
          <AccessCode {...props} />
        </ol>
      </section>
    )
  }
  return null
}

function AccessCode(props: ResourceType) {
  const { vlabAccessCode } = props

  if (vlabAccessCode) {
    return <li>Your access code is: {vlabAccessCode}</li>
  }
  return null
}

import Page from './page'

export default function Forbidden() {
  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">Forbidden</h1>
        <p className="text-center">You must have a valid account on Siemens.com to visit this page.</p>
      </section>
    </Page>
  )
}

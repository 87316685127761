import Page from './page'

export default function Loading() {
  return (
    <Page>
      <section className="flex flex-group">
        <div className="center-block m-t-xl">
          <div className="loader" />
        </div>
      </section>
    </Page>
  )
}

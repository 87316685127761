import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import axios from 'axios'
import Masonry from 'react-masonry-component'

import { ResourceType } from '../../@types'

import MeetingInformation from './meetingInformation'
import ReferenceMaterials from './referenceMaterials'
import Skytap from './skytap'
import SupportLearningCenter from './supportLearningCenter'
import VirtualLab from './virtualLab'
import Vlab from './vlab'
import Footer from './footer'
import CourseSchedule from './courseSchedule'
import GetCertified from './getCertified'
import Survey from './survey'

import NotFound from '../notFound'
import Forbidden from '../forbidden'
import Expired from '../expired'
import Error from '../error'
import Page from '../page'
import Loading from '../loading'

type Params = {
  classID: string,
  registrationID: string,
}

async function fetchResource(classID: string, registrationID: string = '', fetchToken: () => Promise<string>) {
  const token = await fetchToken()
  const response = await axios.get(`/api/resources/${classID}/${registrationID}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}

function Main() {
  const { getAccessTokenSilently } = useAuth0()
  const { classID, registrationID }: Params = useParams()

  const [status, setStatus]: [number | undefined, (error: number) => void] = useState(0)
  const [resource, setResource]: [ResourceType | undefined, (resourceType: ResourceType) => void] = useState()

  useEffect(() => {
    fetchResource(classID, registrationID, getAccessTokenSilently)
      .catch(err => {
        if (err.response?.status) {
          return setStatus(err.response.status)
        }
        setStatus(500)
      })
      .then(data => {
        if (data) {
          setStatus(200)
          setResource(data)
        }
      })
  }, [classID, registrationID])

  switch (status) {
    case 200:
      return resource ? <Resource {...resource} /> : <Error />
    case 403:
      return <Forbidden />
    case 404:
      return <NotFound />
    case 410:
      return <Expired />
    case 500:
      return <Error />
    default:
      return <Loading />
  }
}

function Resource(props: ResourceType) {
  const { name } = props
  const { user, logout } = useAuth0()
  if (!user) {
    return null
  }

  return (
    <Page>
      <section className="flex flex-group">
        <h1 className="flex-grow">{name}</h1>
        <div className="p-b">
          Welcome, {user.given_name}
          <small className="m-l" style={{ lineHeight: '5em' }}>
            <button className="btn btn-outline pull-right" onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </button>
          </small>
        </div>
      </section>

      <CourseSchedule {...props} />
      <VirtualLab {...props} />
      <SupportLearningCenter {...props} />

      <section className="bg-white p-x-lg p-y-md raised-xl m-b-md text-center">
        <strong>
          By accessing and using Siemens EDA learning services, you agree to the{' '}
          <a
            href="https://mlc-assets.s3.us-west-2.amazonaws.com/public/eda-one-glance/LSA-V2-1-English.pdf"
            target="_blank">
            Terms & Conditions
          </a>
          .
        </strong>
      </section>

      <Masonry
        className="grid"
        options={{
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer',
          itemSelector: '.grid-item',
          percentPosition: true,
        }}>
        <div className="gutter-sizer" />
        <div className="grid-sizer" />
        <Skytap {...props} />
        <MeetingInformation {...props} />
        <Vlab {...props} />
        <ReferenceMaterials {...props} />
        <GetCertified {...props} />
        <Survey {...props} />
      </Masonry>

      <Footer {...props} />
    </Page>
  )
}

export default withAuthenticationRequired(Main, {
  returnTo: window.location.pathname,
  onRedirecting: () => {
    return <Loading />
  },
})

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

function demandEnv(envVarName: string): string {
  const envVar = process.env[envVarName]
  if (envVar) return envVar

  throw new Error(`Missing ${envVarName}`)
}

function onRedirectCallback(appState: AppState | undefined) {
  history.replace(appState?.returnTo || window.location.pathname)
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={demandEnv('REACT_APP_AUTH0_DOMAIN')}
      clientId={demandEnv('REACT_APP_AUTH0_CLIENT_ID')}
      redirectUri={demandEnv('REACT_APP_AUTH0_CALLBACK_URL')}
      onRedirectCallback={onRedirectCallback}>
      <App history={history} />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

import { ReactNode } from 'react'

export default function Page({ children }: { children: ReactNode }) {
  return (
    <div className="container">
      <h2 style={{ lineHeight: '5rem' }}>
        <strong className="text-kicker">Training Resources</strong>
      </h2>
      {children}
    </div>
  )
}

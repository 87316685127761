const dayjs = require('dayjs')

export function humanFileSize(size) {
  if (!size) {
    return ''
  }

  let i = Math.floor(Math.log(size) / Math.log(1024))

  if (i === 0) {
    i = 1
  }

  const unit = ['B', 'KB', 'MB', 'GB', 'TB'][i]
  const roundedSize = (size / Math.pow(1024, i)).toFixed(2)

  return `${Math.floor(roundedSize)} ${unit}`
}

export function date(date) {
  return dayjs(date).format('MMM D')
}

export function dateRange(start, end, year = true, locale = 'en') {
  start = dayjs(start)
  end = dayjs(end)

  year = year ? dayjs(start).format('YYYY') : ''

  if (start.month() !== end.month()) {
    return `${date(start)}–${date(end)}, ${year} ${start.format('h')}–${end.format('h a')}`
  } else {
    if (date(start) === date(end)) {
      return `${date(start)}, ${year} ${start.format('h')}–${end.format('h a')}`
    }
    return `${date(start)}–${end.format('D')}, ${year} ${start.format('h')}–${end.format('h a')}`
  }
}

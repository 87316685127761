import { ResourceType } from '../../@types'
import Icon from '../components/icon'

export default function CourseSchedule(props: ResourceType) {
  const { deliveryMethod, dailySchedule } = props

  if (deliveryMethod === 'Online Training' && dailySchedule?.url) {
    return (
      <section>
        <div className="row">
          <div className="col-sm-4">
            <p>
              <a className="btn btn-lg btn-primary display-block" href={dailySchedule.url}>
                <Icon name="calendar" /> Course Schedule
              </a>
            </p>
          </div>
        </div>
      </section>
    )
  }
  return null
}

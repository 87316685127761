import { ResourceType } from '../../@types'
import { dateRange } from '../utils'

export default function Footer(props: ResourceType) {
  const { name, startAtInLocalTime, endAtInLocalTime } = props

  const datetime = dateRange(startAtInLocalTime?.replace('.000Z', ''), endAtInLocalTime?.replace('.000Z', ''))

  const subject = `Problems with Resources for Class ${name}`
  const body = `\n\nClass:\n${name} ${datetime}`
  const mailUrl = `mailto:SysAdmin_EDA.SISW@siemens.com?subject=${subject}&body=${body}`

  return (
    <footer className="m-t-md">
      <p className="text-center text-xxl">
        <strong>
          Please <a href={mailUrl}>contact us</a> if you experience issues with your class setup.
        </strong>
      </p>
    </footer>
  )
}
